<template>
  <v-dialog
    v-model="show"
    max-width="25rem">
    <v-card class="pa-4 text-center">
      <v-card-title class="d-block">
        {{ question }}
      </v-card-title>
      <v-card-subtitle v-if="message" class="d-block">
        {{ message }}
      </v-card-subtitle>
      <v-btn
        @click.stop="response=true"
        class="ma-2"
        large
        color = "success"
        >Sim
      </v-btn>
      <v-btn
        @click.stop="response=false"
        class="ma-2"
        large
        color = "error"
        >Não
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  /* eslint-disable */
  name: 'dialogConfirm',
  props: ['showDialog', 'question', 'message',],
  data: () => ({
    response: null,
  }),
  methods: {

  },
  mounted() {

  },
  created() {

  },
  watch:{
    response: function() {
      this.$emit("responseDialog", this.response);
      this.response = null;
      this.show = false;
    }
  },
  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(val) {
        if (!val)
          this.$emit("hideDialog");
      }
    }
  },
}
</script>

<style scoped>

</style>